.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.App-logo {
  width: 68px;
  height: 19px;
  top: 4px;
}

.modal-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modal-close {
  right: -0.58em;
  top: 0.3em;
}

.App-poweredBy {
  pointer-events: none;
  position: absolute;
  left: 4px;
  font-size: 16px;
}

.section {
  max-width: 700px;
  align-self: flex-start;
}

.App-header {
  height: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 5vw 4vw;
}

.App-content {
  min-height: 90vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em;
  overflow: hidden;
  overflow-y: auto;
}

.App-link {
  color: #61dafb;
}

.card {
  /* Add shadows to create the "card" effect */
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  margin: 1em;
  border-radius: 5px;
  gap: 1em;
}

.center {
  display: flex;
  justify-content: center;
}

.center-content {
  align-self: center;
}

.app-center {
  height: 90vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.question-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  padding-left: 40px;
  padding: auto;
  /* color: black; */
  word-wrap: break-word;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.button-container {
  max-height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
}

video {
  border-radius: 4px;
  transition: width 1s, height 1s;
  transition-timing-function: ease-in-out;
}

.prompt-video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.prompt-video video {
  box-shadow: 0 15px 16px 0 rgba(8, 0, 0, 0.388);
}

.prompt-video video,
.answer-container video {
  max-height: 47vh;
}

/* Media query for resolutions lower than 1080p */
@media (max-resolution: 1919.99dpi) {
  .prompt-video video,
  .answer-container video {
    max-height: 22vh;
  }
}

/* Media query for resolutions above 1080p */
@media (min-resolution: 1920dpi) {
  .prompt-video video,
  .answer-container video {
    max-height: 45vh;
  }
}

.answer-container {
  min-height: fit-content;
}

.text-area {
  width: 75vw;
  min-width: 225px;
  max-width: 450px;
  height: 100%;
}

.modal-container {
  width: 75vw;
  min-width: 200px;
  max-width: 450px;
  height: 100%;
}

.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.timer-text {
  font-size: 24px;
  color: gray;
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
}

.amplify-tabs {
  display: none !important;
}

@media only screen and (max-width: 700px) {
  h1,
  h2 {
    font-size: large;
  }

  h3,
  h4 {
    font-size: medium;
  }

  h5,
  h6 {
    font-size: small;
  }

  p,
  marquee {
    font-size: smaller;
  }
}

.option-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  margin: 1em 4px;
}

/* .option-text label{
  margin-bottom: 10px;
} */

.marquee {
  padding: 1em 0;
}

.progress-bar {
  padding-top: 1em;
}

.audio-container {
  /* padding: 1em; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.width-100 {
  width: 100%;
}

.audio-prompt-container {
  border: #bcbcbc 1px solid;
  border-radius: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 1em;
}

.audio-prompt-container:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.steps-action {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 5px;
}

.app-center {
  width: 100%;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registration-page {
  padding: 1em;
}

.select-option-text {
  min-width: 100%;
  inline-size: min-content;
  word-break: break-all;
}

.circle-bar {
  position: absolute;
  bottom: 100px;
  left: 16px;
}

.text-start {
  width: 100%;
  align-self: flex-start;
  overflow-y: auto;
  text-align: justify;
  max-height: 12vh;
}

.list {
  list-style: circle;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

.list-item {
  content: "\2022"; /* Unicode character for bullet point */
  color: #bcbcbc; /* Customize the color of the bullet point */
  display: list-item;
  margin: 1em;
}

.info {
  width: "-webkit-fill-available" !important ;
  margin-top: 1.5em;
  border: #0000001c 1px solid;
  border-radius: 8px;
  max-width: 100%;
}

.item {
  font-family: sans-serif;
  border-top: 1px solid #ffffff;
  background-color: #fcfcfc;
  border-bottom: 1px solid #999999;
  border-radius: 6px;
  margin: 8px;
  padding: 8px 8px 8px 16px;
  opacity: 1;
}

.item .title {
  font-weight: bold;
  font-size: 125%;
  margin: 0;
  margin-bottom: 0.3rem;
}

.item .body {
  margin: 0;
  margin-bottom: 0.3rem;
}

.item .small {
  font-size: 66%;
  text-align: right;
}

.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #666666;
}

.dl-item.floating {
  width: 85%;
}
